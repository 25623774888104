import React, { useState, useEffect } from "react";
import Intro from "../developer/Intro";
import Projects from "../developer/Projects";
import Contact from "../developer/Contact";
import {
  motion,
  useScroll,
  useMotionValue,
  useSpring,
  useMotionTemplate,
} from "framer-motion";
import { FaICursor } from "react-icons/fa";

const DeveloperPage = () => {
  let mouseX = useMotionValue(0);
  let mouseY = useMotionValue(0);

  function handleMouseMove(event) {
    const { clientX, clientY, currentTarget } = event;
    const { left, top } = currentTarget.getBoundingClientRect();

    mouseX.set(clientX - left);
    mouseY.set(clientY - top);
  }

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  return (
    <div
      
      className="bg-gray-900 text-white"
    >
      <motion.div
        className="fixed top-0 right-0 left-0 h-2 bg-yellow-400 z-20 transform origin-top-left"
        style={{ scaleX }}
      />
      
      <div className='relative' onMouseMove={handleMouseMove}>
      <motion.div
        className="absolute z-10 inset-0 pointer-events-none"
        style={{
          background: useMotionTemplate`radial-gradient(circle at ${mouseX}px ${mouseY}px, #facc1510, transparent 5%)`,
        }}
      />
      
        <Intro />

        <Projects />

        <Contact />
      </div>
    </div>
  );
};

export default DeveloperPage;

import React from "react";
import Intro from "../creator/Intro";
import YouTube from "../creator/YouTube";
import Gallery from "../creator/Gallery";

const CreatorPage = () => {
  return (
    <div className="h-screen bg-shade3 text-bodyText">
      {/* <Intro />
      <YouTube />
      <Gallery /> */}
      <div className="h-full flex items-center justify-center text-4xl 4xl:text-9xl font-creatorHeading text-center">
        Under
        <br />
        Construction
      </div>
    </div>
  );
};

export default CreatorPage;

import React, { useState } from "react";
import { motion } from "framer-motion";

import WhatsApp from "../assets/whatsapp.png";
import LinkedIn from "../assets/linkedin.png";
import Gmail from "../assets/gmail.png";
import Phone from "../assets/phone.png";
import GitHub from "../assets/github.png";

const Contact = () => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleHover = (item) => {
    setHoveredItem(item);
  };

  const handleHoverEnd = () => {
    setHoveredItem(null);
  };

  const contactItems = [
    {
      id: 1,
      type: "mail",
      image: Gmail,
      text: "Send Email",
      label: "Mail",
      url: "gandodilsashank@gmail.com",
    },
    {
      id: 2,
      type: "number",
      image: WhatsApp,
      text: "Send a Message",
      label: "WhatsApp",
      url: "+919071153330",
    },
    {
      id: 3,
      type: "link",
      image: LinkedIn,
      text: "Click To View Profile",
      label: "LinkedIn",
      url: "https://www.linkedin.com/in/lakshmi-sashank-gandodi-474043108/",
    },
    {
      id: 4,
      type: "link",
      image: GitHub,
      text: "Click To View Profile",
      label: "GitHub",
      url: "https://github.com/sashank-gl",
    },
  ];

  return (
    <div className="h-screen flex flex-col gap-16 px-6 justify-center items-center bg-gray-900 text-white">
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.3,
          ease: [0, 0.71, 0.2, 1.01],
          scale: {
            type: "spring",
            damping: 5,
            stiffness: 100,
            restDelta: 0.001,
          },
        }}
        className="font-semibold text-center "
      >
        <span className="text-2xl sm:text-3xl md:text-5xl lg:text-6xl 2xl:text-6xl 4xl:text-7xl">Connect for</span> <br />
        <span className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl 2xl:text-8xl 4xl:text-9xl text-red-500">Coding Adventures!</span>
      </motion.div>

      <div className="grid grid-cols-2 xl:grid-cols-4 gap-4">
        {contactItems.map((item) => (
          <motion.a
            key={item.id}
            href={
              item.type === "link"
                ? item.url
                : item.type === "mail"
                ? `mailto:${item.url}`
                : `https://wa.me/${item.url}`
            }
            target={item.type === "link" ? "_blank" : null}
            rel={item.type === "link" ? "noopener noreferrer" : null}
            className={`w-36 sm:w-56 2xl:w-64 4xl:w-72 h-12 2xl:h-14 4xl:h-16 text-base lg:text-lg 2xl:text-xl 4xl:text-2xl flex justify-center items-center bg-white/20 rounded-lg gap-3`}
            onHoverStart={() => handleHover(item)}
            whileHover={{ scale: 1.05 }}
            onHoverEnd={handleHoverEnd}
            transition={{
              duration: 0.3,
              ease: [0, 0.71, 0.2, 1.01],
              scale: {
                type: "spring",
                damping: 5,
                stiffness: 100,
                restDelta: 0.001,
              },
            }}
          >
            {hoveredItem && hoveredItem.id === item.id && (
              <motion.img
                src={item.image}
                className="w-8"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              />
            )}
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {hoveredItem && hoveredItem.id === item.id
                ? item.text
                : item.label}
            </motion.p>
          </motion.a>
        ))}
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import GameAwesomeVideo from "../assets/gameawesome.mp4";
import { useNavigate } from "react-router-dom";
import { motion, useMotionTemplate, useMotionValue } from "framer-motion";

const LandingPage = () => {

  const navigate = useNavigate();
  
  let mouseX = useMotionValue(0);
  let mouseY = useMotionValue(0);

  function handleMouseMove(event) {
    const { clientX, clientY, currentTarget } = event;
    const { left, top } = currentTarget.getBoundingClientRect();

    mouseX.set(clientX - left);
    mouseY.set(clientY - top);
  }

  const handleDeveloperClick = () => {
    navigate("/developer");
  };

  const handleCreatorClick = () => {
    navigate("/creator");
  };

  return (
    <div className="h-screen flex flex-col lg:flex-none lg:grid lg:grid-cols-2">
      {/* Developer Section */}
      <div
        onMouseMove={handleMouseMove}
        onClick={handleDeveloperClick}
        className="relative h-1/2 md:h-screen bg-white group overflow-hidden"
      >
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-8xl  hover:text-3xl sm:hover:text-4xl md:hover:text-5xl lg:hover:text-6xl xl:hover:text-9xl font-bold  transition-all duration-300 cursor-pointer absolute inset-0 z-10 flex items-center justify-center">
          Developer
        </div>
        <motion.div
          className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-all duration-300"
          style={{
            background: useMotionTemplate`radial-gradient(circle at ${mouseX}px ${mouseY}px, #312e8180, transparent 15%)`,
          }}
        />
      </div>

      {/* Creator Section */}
      <div onClick={handleCreatorClick} className="relative h-1/2 md:h-screen ">
        <video
          className="absolute inset-0 w-full h-full object-cover"
          autoPlay
          muted
          loop
          playsinline
        >
          <source src={GameAwesomeVideo} type="video/mp4" />
        </video>
        <div className="bg-black hover:bg-black/80 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-8xl hover:text-3xl sm:hover:text-4xl md:hover:text-5xl lg:hover:text-6xl xl:hover:text-9xl font-bold text-white transition-all duration-300 cursor-pointer absolute inset-0 z-10 flex items-center justify-center">
          Creator
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

import React from "react";
import { motion } from "framer-motion";
const ProjectTemplate = ({ selectedProject }) => {
  return (
    <motion.div
      key={selectedProject ? selectedProject.id : null}
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="h-full w-full"
    >
      {selectedProject.description ? (
        <div className="h-full w-full flex justify-center items-center">
          <div className="w-full h-full flex flex-col gap-5 bg-gray-800 text-gray-200 rounded-b-lg p-4 lg:p-8 shadow-lg">
            <div>{selectedProject.description}</div>

            <div className="grid grid-cols-2 lg:grid-cols-4 place-content-center place-items-center gap-4 mx-0 lg:mx-16 2xl:mx-24 4xl:mx-36">
              {selectedProject.techStack.map((stack, index) => (
                <div key={index} className="min-w-24 lg:min-w-28 2xl:min-w-32 4xl:min-w-40 px-4 py-2 text-center bg-white/20 rounded-lg">
                  {stack}
                </div>
              ))}
            </div>

            <div className="">
              <p className="text-lg font-semibold">Key Features:</p>
              {selectedProject.functionalities.map((functionality, index) => (
                <p className="mb-1" key={index}>{functionality}</p>
              ))}
            </div>

            <div className="flex flex-col sm:flex-row justify-center items-center gap-4 lg:gap-24">
              <a
                href={selectedProject.link}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-yellow-400 text-gray-700 rounded-xl py-3 px-8"
              >
                View Project
              </a>
              <a
                href={selectedProject.githubLink}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-yellow-400 text-gray-700 rounded-xl py-3 px-8"
              >
                View Code
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full bg-gray-800 flex flex-col justify-center items-center">
          <motion.img
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.3 }}
            transition={{ duration: 1.3 }}
            src={selectedProject.logo}
            alt="Logo"
            className="w-80 object-cover"
          />
          <p className="text-3xl">Work In Progress</p>
        </div>
      )}
    </motion.div>
  );
};

export default ProjectTemplate;

import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//Component Imports
import LandingPage from "./pages/LandingPage";
import CreatorPage from "./pages/CreatorPage";
import DeveloperPage from "./pages/DeveloperPage";

const App = () => {
  return (
    <div className="font-default">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/developer" element={<DeveloperPage />} />
          <Route path="/creator" element={<CreatorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;

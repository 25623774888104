import LoanHubLogo from "../../assets/loanhub.png";
import ChattyLogo from "../../assets/chatty.png";
import NPMLogo from "../../assets/npm.png";

const projectDetails = [
  {
    id: 1,
    title: "LoanHub",
    logo: LoanHubLogo,
    description:
      "LoanHub is a robust loan management application facilitating seamless tracking and repayment of loans. Features include dynamic EMI generation, user authentication, and insightful analytics for comprehensive financial monitoring.",
    techStack: ["ReactJS", "NodeJs", "Redux", "MongoDB"],
    functionalities: [
      "Loan Management (Create, Edit, View, Delete)",
      "Loan Lifecycle (Active, Closed & Overdue states)",
      "EMI Management (Generate, Mark as Paid/Unpaid)",
      "Loan Insights and Analytics",
      "User Authentication and Verification",
      "User Profile Management",
      "Security Measures (Password Hashing, JWT Tokens)",
      "Email Verification",
      "Password Management",
      "File Uploads (Profile Pictures)",
      "Error Handling",
    ],

    link: "https://loanhub.sashankgl.xyz/",
    githubLink: "https://github.com/sashank-gl/Loanzz",
  },
  {
    id: 2,
    title: "Custom Select",
    logo: NPMLogo,
    description:
      "CustomSelect package is a customizable select/dropdown component for ReactJS.",
    techStack: ["ReactJS", "NPM"],
    functionalities: [
      "Array of dropdown options",
      "Load options on input box click",
      "Placeholder text for input box",
      "Tailwind CSS class for input box styling",
      "Function to handle selected option",
    ],
    link: "https://www.npmjs.com/package/custom-select-gls",
    githubLink: "https://github.com/sashank-gl/custom-select-gl",
  },
];

export default projectDetails;

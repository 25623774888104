import React, { useState } from "react";
import HeroImage from "../assets/javascript.png";
import ReactLogo from "../assets/skills/react.png";
import ReduxLogo from "../assets/skills/redux.png";
import NodeJS from "../assets/skills/node.png";
import ExpressJS from "../assets/skills/express.png";
import MongoDB from "../assets/skills/mongodb.png";
import Apple from "../assets/companies/apple.png";
import Meta from "../assets/companies/meta.png";
import { motion } from "framer-motion";

const Intro = () => {
  const [rotation, setRotation] = useState([0, 0]);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const xRotation = (clientY / window.innerHeight - 0.5) * 30;
    const yRotation = (clientX / window.innerWidth - 0.5) * 30;

    setRotation([xRotation, yRotation]);
  };

  const handleMouseLeave = () => {
    setRotation([0, 0]);
  };

  return (
    <div className="lg:h-screen lg:flex-row flex flex-col items-center justify-center gap-12 sm:gap-24 lg:gap-12 pt-12 lg:pt-0 mb-36 lg:mb-0">
      <div
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        className="w-1/2 sm:w-1/2 lg:w-1/3 4xl:w-2/5 flex justify-center items-center"
      >
        <motion.img
          style={{ rotateX: rotation[0], rotateY: rotation[1] }}
          whileTap={{ rotateX: 0, rotateY: 0 }}
          transition={{ duration: 0.5 }}
          src={HeroImage}
          alt="developer"
          className="h-full w-full object-cover"
        />
      </div>
      <div className="w-full sm:w-3/4 lg:w-1/2 gap-4 flex flex-col justify-center text-center xl:text-left">
        <p className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-5xl 2xl:text-7xl w-full">
          Hi,
        </p>

        <p className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-6xl 2xl:text-7xl 4xl:text-9xl w-full py-0 xl:py-6 4xl:py-10">
          I'm{" "}
          <span className="font-bold font-defaultHeading text-red-500">
            <span className="animate-pulse">{"<"}</span>
            Sashank
            <span className="animate-pulse">{" />"}</span>
          </span>
        </p>

        <p className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-5xl 2xl:text-7xl ">
          Full Stack Developer
        </p>

        <p className="text-sm sm:text-md md:text-lg lg:text-xl 2xl:text-2xl 4xl:text-3xl lg:mt-4 4xl:mt-12 px-3 sm:px-0 mr-4">
          Full Stack Developer skilled in developing scalable{" "}
          <motion.img
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 0 }}
            src={MongoDB}
            className="w-8 md:w-10 xl:w-12 4xl:w-16 inline-block mx-1"
            alt="MongoDB Logo"
          />
          data models, building RESTful APIs with{" "}
          <motion.img
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 0.1 }}
            src={ExpressJS}
            className="w-8 md:w-10 xl:w-12 4xl:w-16 inline-block mx-1 bg-white/80 rounded-full p-1"
            alt="ExpressJS Logo"
          />{" "}
          , crafting dynamic user interfaces in{" "}
          <motion.img
            initial={{ scale: 0 }}
            whileInView={{ scale: 1, rotate: 360 }}
            transition={{ duration: 1, delay: 0.2 }}
            src={ReactLogo}
            className="w-8 md:w-10 xl:w-12 4xl:w-16 inline-block mx-1 "
            alt="React Logo"
          />{" "}
          with{" "}
          <motion.img
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 0.3 }}
            src={ReduxLogo}
            className="w-8 md:w-10 xl:w-12 4xl:w-16 inline-block mx-1"
            alt="Redux Logo"
          />
          , and ensuring end-to-end security in{" "}
          <motion.img
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 0.4 }}
            src={NodeJS}
            className="w-8 md:w-10 xl:w-12 4xl:w-16 inline-block mx-1"
            alt="NodeJS Logo"
          />{" "}
          , with a background that includes renowned companies like{" "}
          <motion.img
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
            src={Apple}
            className="w-8 md:w-10 xl:w-12 4xl:w-16 inline-block mx-1"
            alt="React Logo"
          />{" "}
          and{" "}
          <motion.img
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
            src={Meta}
            className="w-8 md:w-10 xl:w-12 4xl:w-16 inline-block mx-1"
            alt="React Logo"
          />{" "}
          .
        </p>

        {/* <p className=" w-96">
            Based in Hyderabad, I specialize in crafting scalable software
            solutions using React, Node, Express, MongoDB, and Tailwind CSS.
            With precision and innovation, I bring ideas to life, creating
            efficient applications from responsive interfaces to robust
            server-side functionalities.
          </p> */}
      </div>
    </div>
  );
};
export default Intro;

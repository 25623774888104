import React, { useState } from "react";

import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { AiFillGithub, AiOutlineClose } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import projectDetails from "./projects/ProjectDetails";
import ProjectTemplate from "./projects/ProjectTemplate";
const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(projectDetails[0]);
  return (
    <div className="relative lg:h-screen flex flex-col items-center bg-gray-900 justify-center">
      <div className="absolute left-0 w-12 lg:w-48 -rotate-90 text-5xl lg:text-9xl flex  justify-center font-defaultHeading text-white">
        PROJECTS
      </div>
      <div className="flex bg-gray-800 w-2/3 rounded-t-lg pt-2 pl-2 border-b text-xs lg:text-base 2xl:text-xl 4xl:text-2xl">
        {projectDetails.map((project) => (
          <div
            className={`flex items-center justify-center cursor-pointer gap-2 py-1 lg:py-4 px-8 ${project === selectedProject ? 'bg-white/80 rounded-t-lg text-gray-700 font-semibold' : null}`}
            key={project.id}
            onClick={() => setSelectedProject(project)}
          >
            <img src={project.logo} alt="Logo" className="w-8 object-cover" />
            <p>{project.title}</p>
          </div>
        ))}
      </div>
      <div className="min-h-1/2  w-2/3 text-xs lg:text-base 2xl:text-xl 4xl:text-2xl">
        <AnimatePresence>
          <ProjectTemplate selectedProject={selectedProject} />
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Projects;
